//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "storeContainerIndex",
  data() {
    return {
      ossPath:'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/Layout/Area/',
      location,
      loading: true,
      mobile:"",
      username:"",
      address:"",
      storeOption: [],
      storeInfo:{},
      roleList:[],
      storeCode: "",
      storeName:"",
      displayList:[],
      layoutAreaList:[],
    };
  },
  filters:{
    toFixed1(val){
      return parseFloat(val).toFixed(1)
    }
  },
  created() {
    // const that = this;
    // this.getUserInfo();
    // this.storeName = this.$route.query.storeName;
    // this.storeCode = this.$route.query.storeCode;
    // window.document.title = this.storeName;
  },
  activated() {
    if(this.storeCode){
      this.getDisplayList(this.storeCode)
    }else{
       this.getUserInfo();
    }
    
  },
  methods: {
    storeChange(value) {
      console.log(value);
      this.getStoreInfo(value)
      this.storeOption.map(v =>{
        if(v.storeId == value){
          this.storeName = v.text
          this.getDisplayList(this.storeCode)
          window.document.title = v.text
        }
      })
    },
     // position 为关闭时点击的位置
    // instance 为对应的 SwipeCell 实例
    deleteItem({position,name}) {
      let that = this;
      switch (position) {
        case 'right':
           this.$dialog.confirm({
            message: '确定删除吗？',
          }).then(() => {
            let currentItem = that.displayList[name];
            console.log(currentItem);
            that.axios.post('/'+ this.$ajaxPrefix.consumer +"/store/display/delete",{
                lmnum: currentItem.lmnum,
                kunnr: currentItem.kunnr,
                layvr: currentItem.layvr
              })
            .then((res) => {
                console.log(res);
                if(res.data.data){
                  that.$toast({ message: "删除成功", duration: 2000 });
                  that.displayList.splice(name,1)
                }
              });
            
          }).catch(() => {
            // on cancel
          });
          break;
      }
    },
    addDisplay(){
      this.$router.push({name: 'StoreContainerDetail', params: {
        storeName:this.storeName,
        storeCode:this.storeCode
      }})
    },
    goDetail(index){
      this.$router.push({name: 'StoreContainerDetail', params: {
        ...this.displayList[index],
        storeName:this.storeName,
        storeCode:this.storeCode
      }})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getDisplayList(storeCode) {
      const that = this;
      this.$toast.loading({
        message: "数据获取中",
        forbidClick: true,
        duration: 0,
      });
      this.axios
        .get('/'+ this.$ajaxPrefix.consumer +"/store/display/list",{params:{storeCode}})
        .then((res) => {
          that.$toast.clear();
          if (res.data && res.data.data) {
            res.data.data.map(v2 =>{
              v2.sortfPath = v2.sorftPath
              v2.flpkt =parseInt(v2.flpkt)
            })
            that.displayList = res.data.data;
          }
        });
    },
    getLayoutAreaList(storeCode) {
      const that = this;
      this.axios
        .get('/'+ this.$ajaxPrefix.consumer +"/store/layoutArea/list",{params:{storeCode}})
        .then((res) => {
          if (res && res.data) {
            that.layoutAreaList = res.data.data;
            that.getDisplayList(that.storeCode);
          }
        });
    },
    getUserInfo() {
      this.loading = true;
      const that = this;
      this.axios
        .get('/' + this.$ajaxPrefix.consumer +"/v1/wx/cp/getUserInfo")
        .then((res) => {
          console.log(res);
           that.loading = false;
          if (res && res.data && res.data.data) {
            that.roleList = res.data.data.attr.roleList;
            let saleStore = res.data.data.attr.saleStore
            //格式化下拉列表参数
            saleStore.map(v =>{
              v.text = v.storeId + v.title
              v.value = v.storeId
            })
            that.storeOption = saleStore;
            that.mobile = res.data.data.mobile;
            that.username = res.data.data.username;
            that.storeCode = saleStore[0].storeId
            that.getStoreInfo(that.storeCode)
            that.getDisplayList(that.storeCode)
            that.storeName = saleStore[0].text
            window.document.title = saleStore[0].text
          }
        });
    },
    getStoreInfo(storeId) {
      const that = this;
      this.axios
        .get('/'+ this.$ajaxPrefix.consumer +"/v1/store/pro/getByStoreId?storeId=" + storeId)
        .then((res) => {
          console.log(res);
            that.storeInfo = res.data
        });
    },
  },
};
